<template>
  <div id="Podcasts">
    <h1>Podcasts</h1>

    <div class="podcasts">
      <div class="podcast"
      v-for="(pod, idx) in podcasts"
      :key="idx">

      <div
        v-if="pod.source != 'youtube'"
        :style="{'background-image': 'url('+ pod.thumbnail +')' }"
        class="cover-img"
        @click="goto(pod.embed)"
        >
      </div>

        <!-- <img
        :src="info.photo"
        class="cover-img"
        />
        <div class="details">
          <p class="sector">{{info.sector}}</p>
          <h3 class="title">{{info.title}}</h3>
          <p>{{info.description}}</p>
          <small>{{info.created}}</small>
        </div> -->

        <div class="yt-vid" v-if="pod.source == 'youtube' ">
          <iframe width="300" height="150" :src="pod.embed" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
          </iframe>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
// import {rtdb} from '@/_firebase/init.js'
// import axios from 'axios'

export default {
  name: 'Podcasts',
  components: {
  },
  data(){
    return{
      podcasts: [
        {
          embed: 'https://www.youtube.com/embed/YDvluIGNzXQ',
          thumbnail: null,
          source: "youtube",
          link: ""
        },
        {
          embed: 'https://www.youtube.com/embed/PaU2r2rjTQc',
          thumbnail: null,
          source: "youtube",
          link: ""
        },
        {
          embed: 'https://runninginproduction.com/podcast/25-a-cryptocurrency-powered-e-commerce-store-called-strmline',
          thumbnail: 'https://firebasestorage.googleapis.com/v0/b/tycooperaow-xx99xx.appspot.com/o/site-assets%2FScreen%20Shot%202021-07-26%20at%2012.50.25.png?alt=media&token=61d73d53-62d4-47fb-9ea5-e7825105e63f',
          source: 'cutom',
          link: ''
        },
        {
          embed: 'https://podcasts.apple.com/us/podcast/live-breathe-sleep-crypto-w-ty-cooper/id1542620301?i=1000520641295',
          thumbnail: 'https://firebasestorage.googleapis.com/v0/b/tycooperaow-xx99xx.appspot.com/o/site-assets%2FIMAGE%202021-07-26%2013%3A06%3A46.jpg?alt=media&token=76de44be-e392-4ad4-8cc8-4054dff5e550',
          source: 'cutom',
          link: ''
        },
      ]
    }
  },
  methods:{
    goto(link){
      window.open(link, '_blank')
    }
  },
}
</script>
<style scoped>
#Podcasts{
  width: 100%;
  height: 100%;
  min-height: 100vh;
  color: #fff;
}
.podcasts{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-items: stretch;
  align-items: stretch;
  margin: 2rem auto;
  width: 96%;
}
.podcast{
  width:100%;
  height: 100%;
  cursor:pointer;
  /* background-color: #000; */
  border-radius: 10px;
  transition: .7s ease-out;
}
.podcast .cover-img{
  width: 300px;
  height: 150px;
  margin: 0;
  transition: 1s;
  position:relative;
  overflow:hidden;
  border: 5px solid var(--bg-default);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}
.podcast .details{
  background-color: #000;
  text-align: left;
  margin: 0;
  margin-top: -1rem;
  padding: 1rem;
  border-radius: 10px;
}
.podcast .details .title{
  font-size: 1.7rem;
}
.podcast .details .sector{
  color: #7cb0e5;
  font-weight: bold;
}
.podcast .details:hover{
  /* opacity: 1; */
}
.podcast:hover{
  transform: translateY(-10px)
}
</style>
